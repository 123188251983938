<template>
  <div class="col-12 p-0 my-3">
    <div class="row justify-content-center">
      <div class="col-auto p-0 relative text-center" v-for="steps, key in wizardSteps" :key="key">
        <div class="row mx-0 mb-2">
          <div class="col-auto ps-0 pe-2 text-center">
            <div class="wizard_divider" :class="{opacity: key === 0}">
            </div>
          </div>
          <div class="col-auto p-0 text-center">
            <div class="wizard_step" :class="{active: checkString(steps.checkActiveString)}">
              <div class="row m-0 h-100 align-items-center">
                <div class="col-12 p-0 text-center">
                  {{ steps.stepNumber }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto pe-0 ps-2 text-center">
            <div class="wizard_divider" :class="{opacity: key === wizardSteps.length - 1}">
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-12 p-0 text-center small_font" v-html="steps.title">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheSteps',
  props: ['activeComponent', 'wizardSteps'],
  methods: {
    checkString (val) {
      if (this.activeComponent) {
        const theString = this.activeComponent.toLowerCase()
        return theString.includes(val)
      }
    }
  }
}
</script>

<style scoped>
.wizard_step {
  border-radius: 50%;
  color: var(--green-color-dark);
  background-color: var(--gold-color);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 10px;
  width: 22px;
  height: 22px;
}
.wizard_step.active {
  background-color: var(--green-color-dark);
  color: var(--gold-color);
}
.wizard_divider {
  border-bottom: 1px solid rgba(104, 103, 103, 0.4);
  width: 11px;
  height: 11px;
}
.opacity {
  opacity: 0;
}
.small_font {
  font-size: 11px;
  font-family: "quicksand_bold", Sans-serif;
}
@media (min-width: 992px) {
  .wizard_step {
    font-size: 11px;
    width: 30px;
    height: 30px;
  }
  .wizard_divider {
    width: 15px;
    height: 16px;
  }
}
</style>
